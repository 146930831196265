<template>
  <section
    class="wurf part relative"
  >
    <div v-view class="constrain animate in-bottom">
      <div class="row-12">
        <div class="col-12 md:col-10 md:offset-1">
          <h2 v-html="payload.titel" />
          <div class="parents dog-grid">
            <dog-card
              v-for="dog in payload.parents"
              :key="dog.id"
              :dog="dog"
              :parent="true"
              :wurf="payload.title"
            />
          </div>
          <div class="children dog-grid">
            <dog-card
              v-for="dog in filteredDogs"
              :key="dog.id"
              :dog="dog"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { computed } from 'vue';

export default {
  props: { payload: { type: Object, default: Object } },
  setup(props) {
    const filteredDogs = computed(
      () => props.payload.dogs.filter(
        (dog) => !props.payload.parents.some((edog) => edog.id === dog.id),
      ),
    );
    return {
      filteredDogs,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/styles/_mixin.scss';

.wurf {
  @apply bg-blue;

  @include responsive('padding', px(60) 0, px(124) 0);
}

h2 {
  @include responsive('margin-bottom', px(20), px(57));
}

.dog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-column-gap: px(90);
  grid-row-gap: px(55);
  margin-bottom: px(55);
}
</style>
